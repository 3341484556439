<template>
  <v-app style="min-height: 2px">
    <div>
      <div class="row justify-content-end">
        <b-button
          variant="primary"
          size="sm"
          squared
          @click="$bvModal.show('modal-create-invitation')"
        >
          Buat Jadwal Kirim Undangan
        </b-button>
      </div>

      <template v-if="items.length > 0">
        <div class="row justify-content-end mt-3">
          <div class="col-md-3">
            <treeselect
              v-model="filter.member_role_id"
              :multiple="false"
              placeholder="Saring Peran"
              :options="member_roles"
              @input="filterByMemberRole"
            />
          </div>
          <div class="col-md-4">
            <b-input-group>
              <b-form-input
                type="text"
                v-model="filter.name"
                placeholder="Cari Nama"
              ></b-form-input>
              <template #append>
                <b-button variant="primary" @click="filterByName"><i class="fas fa-search"></i></b-button>
                <!-- <b-button variant="danger">Reset</b-button> -->
              </template>
            </b-input-group>
          </div>
        </div>
        <b-table
          striped
          hover
          :items="items"
          :fields="fields"
          class="mt-3"
          responsive
        >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'action' ? '15%' : '' }"
            />
          </template>

          <template #cell(photo)="data">
            <div
              class="
                d-flex
                justify-content-start
                align-items-center
                image-container
              "
            >
              <div class="image-input-wrapper" style="position: relative">
                <!-- <img class="image" :src="data.item.member_image" /> -->
                <b-img :src="data.item.member_image" alt="User Image"></b-img>
              </div>
            </div>
          </template>

          <template #cell(sent_time)="data">
            <span v-if="data.item.sent_time == null">-</span>
            <span v-if="data.item.sent_time != null">{{
              data.item.sent_time_display
            }}</span>
          </template>

          <template #cell(action)="data">
            <b-button
              size="sm"
              class="mr-1 btn-outline-primary"
              variant="outline-primary"
              v-b-tooltip.hover
              @click="resendInvitation(data.item.id)"
              >
              <span v-if="data.item.sent_status">Kirim Ulang</span>
              <span v-if="!data.item.sent_status">Kirim Undangan</span>
              </b-button>
          </template>
        </b-table>

        <b-pagination
          v-if="items.length != 0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          @page-click="pageOnClick"
          class="mt-4"
        ></b-pagination>
      </template>

      <b-modal
        id="modal-create-invitation"
        title="Buat Invitation"
        @ok="formOnsubmit"
      >
        <b-form-group
          id="input-group-sent-time"
          label="Tanggal Kirim Invitation:"
          label-for="input-sent-time"
        >
          <v-datetime-picker
            v-model="sent_time"
            :time-picker-props="timeProps"
            time-format="HH:mm:ss"
          ></v-datetime-picker>
          <small class="text-danger">{{ error.sent_time }}</small>
        </b-form-group>
      </b-modal>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
export default {
  props: {
    pitching_event_id: String,
  },
  data() {
    return {
      timeProps: {
        useSeconds: true,
        ampmInTitle: true,
      },
      filter: {
        name: "",
        pitching_event_id: "",
        member_role_id: "",
      },
      // Pagination
      perPage: 30,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
          sortable: true,
        },
        {
          key: "member_name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "member_role_name",
          label: "Peran",
          sortable: true,
        },
        {
          key: "sent_time",
          label: "Tanggal Kirim",
          sortable: true,
        },
        {
          key: "sent_status_display",
          label: "Status",
          sortable: true,
        },
        {
          key: "action",
          label: "",
          tdClass: "nowrap",
        },
      ],
      items: [],
      member_roles: [],
      form: {
        pitching_event_id: "",
        sent_time: "",
      },
      sent_time: new Date(),
      error: {
        sent_time: "",
      },
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&pitching_event_id=${this.filter.pitching_event_id}&member_role_id=${this.filter.member_role_id}&name=${this.filter.name}`;
      console.log('filterparams', filterParams)
      let response = await module.paginate(
        "api/pitching-invitations",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async resendInvitation(id) {
      let response = await module.submit(
        this.form,
        `api/pitching-invitations-resend/${id}`
      );

      if (response.state != "error") {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.pagination();
      }
    },

    async getMemberRoleOption() {
      let response = await module.setTreeSelect("api/member-roles", '?id_selected=345269');
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.member_roles = response.data;
        this.member_roles.unshift({
          label: "Saring Peran",
          id: "",
        });
      }
    },

    filterByName() {
      this.pagination();
    },
    filterByMemberRole(evt) {
      if (typeof evt != "undefined") {
        this.filter.member_role_id = evt;
        this.pagination();
      } else {
        this.filter.member_role_id = "";
        this.pagination();
      }
    },

    async formOnsubmit() {
      this.form.pitching_event_id = this.filter.pitching_event_id
      let start_date = new Date(this.sent_time).toISOString().substr(0, 10);
      let start_time = new Date(this.sent_time).toTimeString().substr(0, 8);
      this.form.sent_time = start_date + " " + start_time;

      // Make Request
      let response = await module.submit(
        this.form,
        `api/pitching-invitations-set-schedule/${this.filter.pitching_event_id}`
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        console.log(response);
        this.pagination();
      }
    },
  },
  mounted() {
    // this.pagination();
    this.getMemberRoleOption();
    this.filter.pitching_event_id = this.$route.params.id

    this.$root.$on("runPaginationInvitation", (pitching_event_id) => {
      console.log('jalankan pagination invitation')
      this.filter.pitching_event_id = pitching_event_id
      this.pagination();
    });
    this.$root.$on("refreshInvitation", () => {
      this.pagination();
    });
    this.$root.$on("deleteParticipantEmit", () => {
      this.pagination();
    });
  },
};
</script>

<style>
.v-application .v-application--wrap {
  min-height: 2px !important;
}

.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>