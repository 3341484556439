<template>
  <div>
    <div class="col-12">
      <div class="card">
        <div
          class="card-body classroom-image"
          :style="
            'background-image: url(' +
            detail.image_header +
            '); background-size: cover;'
          "
        ></div>
        <!-- <div class="card-body filter-image"></div> -->
        <div class="place-title">
          <div
            class="classroom-title shadow-sm card card-custom px-3 py-2 mt-1"
          >
            <h2 class="text-primary mb-0">{{ detail.name }}</h2>
          </div>
          <!-- <div class="d-flex justify-content-end" v-if="!isMobile()">
            <div class="shadow p-2 d-inline card card-custom mt-2 mr-2">
              <span class="font-weight-bold">{{participants.length}} Peserta dan mitra,</span>
                    <span class="font-weight-bold ml-1">{{judgers.length}} Narasumber,</span>
                    <span class="font-weight-bold ml-1">{{hosts.length}} Moderator</span>
            </div>
          </div> -->
          <!-- <div class="d-flex justify-content-end" v-if="!isMobile()">
            <div class="shadow p-2 d-inline card card-custom mt-2 mr-2">
              <b-button
                @click="classDelete(detail.id)"
                class="mr-1 btn-circle"
                variant="outline-danger"
                v-b-tooltip.hover.top="'Hapus'"
              >
                <i class="fas fa-trash px-0"></i>
              </b-button>
              <b-button
                @click="
                  $router.push({
                    path: '/pitching-events/edit/' + detail.id,
                  })
                "
                class="btn-circle"
                variant="outline-success"
                v-b-tooltip.hover.top="'Edit'"
                ><i class="fas fa-edit px-0"></i>
              </b-button>
            </div>
          </div> -->
          <!-- <div class="" v-if="isMobile()">
            <b-dropdown
              no-caret
              variant="link"
              toggle-class="text-decoration-none"
              :style="'position:absolute; right:10px; text-shadow: 2px 2px #000000'"
              dropleft
              v-if="btn"
            >
              <template #button-content>
                <span style="color: white"
                  ><b-icon-three-dots-vertical></b-icon-three-dots-vertical
                ></span>
              </template>
              <b-dropdown-item>
                <router-link :to="'/pitching-events/edit/' + detail.id"
                  >Edit</router-link
                >
              </b-dropdown-item>
              <b-dropdown-item>
                <span @click="classDelete(detail.id)">Hapus</span>
              </b-dropdown-item>
            </b-dropdown>
          </div> -->
        </div>
        <b-tabs>
          <b-tab title="Peserta" active>
            <div class="container">
              <div class="row my-4">
                <div class="col-12">
                  <div class="row justify-content-end">
                    <span class="font-weight-bold"
                      >{{ participants.length }} Peserta dan mitra,</span
                    >
                    <span class="font-weight-bold ml-1"
                      >{{ judgers.length }} Narasumber,</span
                    >
                    <span class="font-weight-bold ml-1"
                      >{{ hosts.length }} Moderator</span
                    >
                  </div>
                  <div class="row justify-content-between">
                    <div class="col-6 pl-0">
                      <table class="table table-borderless">
                        <tr style="line-height: 0">
                          <td>Mulai Event</td>
                          <td>:</td>
                          <td>{{ detail.start_time_display }}</td>
                        </tr>
                        <tr style="line-height: 0.5">
                          <td>Selesai Event</td>
                          <td>:</td>
                          <td>{{ detail.end_time_display }}</td>
                        </tr>
                      </table>
                    </div>
                    <div class="col-6 pl-0">
                      <table class="table table-borderless">
                        <tr style="line-height: 0">
                          <td>Mulai Discussion</td>
                          <td>:</td>
                          <td>{{ detail.start_pitching_display }}</td>
                        </tr>
                        <tr style="line-height: 0.5">
                          <td>Selesai Discussion</td>
                          <td>:</td>
                          <td>{{ detail.end_pitching_display }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <!-- Peserta -->
                  <hr />
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class=""><h6>Peserta dan Mitra</h6></div>
                    <div class="">
                      <b-button
                        variant="primary"
                        size="sm"
                        @click="addParticipant('participants')"
                        :disabled="participants.length == 20 ? true : false"
                      >
                        Tambah
                      </b-button>
                    </div>
                  </div>
                  <div class="row" style="min-height: 3rem">
                    <!-- <div
                      v-for="(participant, index) in participants"
                      :key="participant.id"
                      class="col-md-2"
                      :class="index > 0 ? 'ml-6' : ''"
                    >
                      <div class="card" style="background-image: url('/img/card-member/cms_card-member2.png');border: none;height: 150px;width: 143px;">
                        <div class="row justify-content-center mt-3" style="width: 152px; max-height: 7.5rem !important;">
                        <b-img v-bind="mainProps" :src="participant.member_image" class="d-block col-12 p-2" rounded="circle" alt="Circle image"></b-img>
                        </div>
                        <div class="d-flex justify-content-center" style="width: 129px;">
                          <span class="font-weight-bold">{{participant.member_name}}</span>
                        </div>
                        <div class="d-flex justify-content-start mt-3 ml-2" style="width: 129px;">
                          <div>
                            <b-badge v-if="participant.confirmation_status" variant="success" class="py-1" style="border-radius: 15px 0 0 15px;font-size: 8.1px;width: 3.4rem; background-image: linear-gradient(#3a80bf, #1bdafe);">KONFIRM</b-badge>
                          </div>
                          <div style="margin-left: 1.5px; margin-right: 0.5px">
                            <b-badge v-if="participant.presence_status" variant="success" class="px-0 py-1" style="border-radius: 1px;font-size: 8.1px;width: 2.45rem; background-image: linear-gradient(#3a80bf, #1bdafe);">HADIR</b-badge>
                          </div>
                          <div>
                            <b-badge v-if="participant.is_finish" variant="success" class="py-1 px-0" style="border-radius: 0px 15px 15px 0px;font-size: 8.1px;width: 2.7rem; background-image: linear-gradient(#3a80bf, #1bdafe);">RAPAT</b-badge>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div
                      class="col-md-2 px-0 d-flex justify-content-between"
                      v-for="(participant, index) in participants"
                      :key="participant.id"
                      :class="index > 0 ? 'ml-4' : ''"
                    >
                      <div class="col-11 pt-0">
                        <div class="card my-card-custom">
                          <div style="margin-left: 130px; height: 3px">
                            <b-button
                              variant="success"
                              size="sm"
                              class="py-0 px-1"
                              squared
                              @click="
                                showDetailParticipant(
                                  participant.id,
                                  'participants'
                                )
                              "
                              ><i class="fas fa-info-circle fa-sm px-0"></i
                            ></b-button>
                            <b-button
                              variant="info"
                              size="sm"
                              class="py-0 px-1"
                              squared
                              @click="
                                getPitchingResultByParticipant(
                                  participant.id,
                                  participant.member_name
                                )
                              "
                              ><i class="fas fa-check fa-sm px-0"></i
                            ></b-button>
                            <b-button
                              variant="danger"
                              size="sm"
                              class="py-0"
                              style="padding: 5px"
                              squared
                              @click="
                                deleteParticipant(
                                  `api/pitching-participants/${participant.id}`
                                )
                              "
                              ><i class="fas fa-times fa-sm px-0"></i
                            ></b-button>
                          </div>
                          <div class="row justify-content-center my-2">
                            <div
                              class="profile-image"
                              style="
                                border-radius: 50%;
                                background: rgb(252, 120, 134);
                                width: 6rem;
                                height: 6rem;
                              "
                            >
                              <b-img
                                v-bind="mainProps"
                                :src="participant.member_image"
                                class="d-block col-12 p-2"
                                rounded="circle"
                                alt="Circle image"
                              ></b-img>
                            </div>
                          </div>
                          <div
                            class="row justify-content-center my-1 mx-1 text-center"
                          >
                            <span class="font-weight-bold text-word-break">{{
                              participant.member_name
                            }}</span>
                          </div>
                          <div
                            class="row no-gutters"
                            style="
                              background: #fc6775;
                              border-radius: 0 0 11px 11px;
                            "
                          >
                            <div
                              class="
                                col
                                d-flex
                                justify-content-center
                                py-1
                                text-center
                              "
                            >
                              <div class="mb-1">
                                <b-badge
                                  variant="success"
                                  v-if="!participant.confirmation_status"
                                  class="py-1"
                                  style="
                                    border-radius: 15px 0 0 15px;
                                    font-size: 8.1px;
                                    width: 3.4rem;
                                    background-color: #617270;
                                  "
                                  >KONFIRM</b-badge
                                >
                                <b-badge
                                  variant="success"
                                  v-if="participant.confirmation_status"
                                  class="py-1"
                                  style="
                                    border-radius: 15px 0 0 15px;
                                    font-size: 8.1px;
                                    width: 3.4rem;
                                    background-image: linear-gradient(
                                      #3a80bf,
                                      #1bdafe
                                    );
                                  "
                                  >KONFIRM</b-badge
                                >
                              </div>
                              <div style="margin-left: 1px; margin-right: 1px">
                                <b-badge
                                  variant="success"
                                  v-if="!participant.presence_status"
                                  class="px-0 py-1"
                                  style="
                                    border-radius: 1px;
                                    font-size: 8.1px;
                                    width: 2.45rem;
                                    background-color: #617270;
                                  "
                                  >HADIR</b-badge
                                >
                                <b-badge
                                  variant="success"
                                  v-if="participant.presence_status"
                                  class="px-0 py-1"
                                  style="
                                    border-radius: 1px;
                                    font-size: 8.1px;
                                    width: 2.45rem;
                                    background-color: #617270;
                                    background-image: linear-gradient(
                                      #3a80bf,
                                      #1bdafe
                                    );
                                  "
                                  >HADIR</b-badge
                                >
                              </div>
                              <div>
                                <b-badge
                                  variant="success"
                                  v-if="!participant.is_finish"
                                  class="py-1 px-0"
                                  style="
                                    border-radius: 0px 15px 15px 0px;
                                    font-size: 8.1px;
                                    width: 2.7rem;
                                    background-color: #617270;
                                  "
                                  >RAPAT</b-badge
                                >
                                <b-badge
                                  variant="success"
                                  v-if="participant.is_finish"
                                  class="py-1 px-0"
                                  style="
                                    border-radius: 0px 15px 15px 0px;
                                    font-size: 8.1px;
                                    width: 2.7rem;
                                    background-color: #617270;
                                    background-color: #617270;
                                    background-image: linear-gradient(
                                      #3a80bf,
                                      #1bdafe
                                    );
                                  "
                                  >RAPAT</b-badge
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Judger -->
                  <hr />
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class=""><h6>Narasumber</h6></div>
                    <div class="">
                      <b-button
                        variant="primary"
                        size="sm"
                        @click="addParticipant('judgers')"
                        :disabled="judgers.length == 4 ? true : false"
                      >
                        Tambah
                      </b-button>
                    </div>
                  </div>
                  <div class="row" style="min-height: 3rem">
                    <div
                      class="col-md-2 px-0 d-flex justify-content-between"
                      v-for="(item, index) in judgers"
                      :key="item.id"
                      :class="index > 0 ? 'ml-4' : ''"
                    >
                      <div class="col-11 pt-0">
                        <div class="card my-card-custom">
                          <div style="margin-left: 130px; height: 3px">
                            <b-button
                              variant="success"
                              size="sm"
                              class="py-0 px-1"
                              squared
                              @click="showDetailParticipant(item.id, 'judgers')"
                            >
                              <i class="fas fa-info-circle fa-sm px-0"></i>
                            </b-button>
                            <b-button
                              variant="danger"
                              size="sm"
                              class="py-0"
                              style="padding: 5px"
                              squared
                              @click="
                                deleteParticipant(
                                  `api/pitching-judgers/${item.id}`
                                )
                              "
                              ><i class="fas fa-times fa-sm px-0"></i
                            ></b-button>
                          </div>
                          <div class="row justify-content-center my-2">
                            <div
                              class="profile-image"
                              style="
                                border-radius: 50%;
                                background: rgb(252, 120, 134);
                                width: 6rem;
                                height: 6rem;
                              "
                            >
                              <b-img
                                v-bind="mainProps"
                                :src="item.member_image"
                                class="d-block col-12 p-2"
                                rounded="circle"
                                alt="Circle image"
                              ></b-img>
                            </div>
                          </div>
                          <div
                            class="row justify-content-center my-1 mx-1 text-center"
                          >
                            <span class="font-weight-bold text-word-break">{{
                              item.member_name
                            }}</span>
                          </div>
                          <div
                            class="row no-gutters"
                            style="
                              background: #fc6775;
                              border-radius: 0 0 11px 11px;
                            "
                          >
                            <div class="col d-flex justify-content-center py-1">
                              <div class="mb-1">
                                <b-badge
                                  variant="success"
                                  class="py-1"
                                  style="
                                    visibility: hidden;
                                    border-radius: 15px 0 0 15px;
                                    font-size: 8.1px;
                                    width: 3.4rem;
                                    background-color: #617270;
                                  "
                                  >KONFIRM</b-badge
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Coach -->
                  <hr />
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class=""><h6>Coach</h6></div>
                    <div class="">
                      <b-button
                        variant="primary"
                        size="sm"
                        @click="addParticipant('partners')"
                      >
                        Tambah
                      </b-button>
                    </div>
                  </div>
                  <div class="row" style="min-height: 3rem;">
                    <div 
                      class="col-md-2 px-0 d-flex justify-content-between" 
                      v-for="(item, index) in partners"
                      :key="item.id"
                      :class="index > 0 ? 'ml-4' : ''"
                    >
                      <div class="col-11 pt-0">
                        <div class="card my-card-custom">
                          <div style="margin-left: 130px;height: 3px;">
                            <b-button variant="success" size="sm" class="py-0 px-1"  squared @click="showDetailParticipant(item.id, 'partners')"><i class="fas fa-info-circle fa-sm px-0"></i></b-button>
                            <b-button variant="danger" size="sm" class="py-0" style="padding: 5px;" squared @click="deleteParticipant(`api/pitching-partners/${item.id}`)"><i class="fas fa-times fa-sm px-0"></i></b-button>
                          </div>
                          <div class="row justify-content-center my-2">
                            <div class="profile-image" style="border-radius: 50%;background: rgb(252, 120, 134);width: 6rem;height: 6rem;">
                              <b-img v-bind="mainProps" :src="item.member_image" class="d-block col-12 p-2" rounded="circle" alt="Circle image"></b-img>
                            </div>
                          </div>
                          <div class="row justify-content-center text-center my-1 mx-0">
                            <span class="font-weight-bold text-word-break">{{item.member_name}}</span>
                          </div>
                          <div class="row no-gutters" style="background: #fc6775;border-radius: 0 0 11px 11px;">
                            <div class="col d-flex justify-content-center py-1">
                              <div class="mb-1">
                                <b-badge variant="success" class="py-1" style="visibility: hidden; border-radius: 15px 0 0 15px;font-size: 8.1px;width: 3.4rem; background-color: #617270;">KONFIRM</b-badge>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Host -->
                  <hr />
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class=""><h6>Moderator</h6></div>
                    <div class="">
                      <b-button
                        variant="primary"
                        size="sm"
                        @click="addParticipant('hosts')"
                        :disabled="hosts.length == 1 ? true : false"
                      >
                        Tambah
                      </b-button>
                    </div>
                  </div>
                  <div class="row" style="min-height: 3rem">
                    <div
                      class="col-md-2 px-0 d-flex justify-content-between"
                      v-for="(item, index) in hosts"
                      :key="item.id"
                      :class="index > 0 ? 'ml-4' : ''"
                    >
                      <div class="col-11 pt-0">
                        <div class="card my-card-custom">
                          <div style="margin-left: 130px; height: 3px">
                            <b-button
                              variant="success"
                              size="sm"
                              class="py-0 px-1"
                              squared
                              @click="showDetailParticipant(item.id, 'hosts')"
                              ><i class="fas fa-info-circle fa-sm px-0"></i
                            ></b-button>
                            <b-button
                              variant="danger"
                              size="sm"
                              class="py-0"
                              style="padding: 5px"
                              squared
                              @click="
                                deleteParticipant(
                                  `api/pitching-hosts/${item.id}`
                                )
                              "
                              ><i class="fas fa-times fa-sm px-0"></i
                            ></b-button>
                          </div>
                          <div class="row justify-content-center my-2">
                            <div
                              class="profile-image"
                              style="
                                border-radius: 50%;
                                background: rgb(252, 120, 134);
                                width: 6rem;
                                height: 6rem;
                              "
                            >
                              <b-img
                                v-bind="mainProps"
                                :src="item.member_image"
                                class="d-block col-12 p-2"
                                rounded="circle"
                                alt="Circle image"
                              ></b-img>
                            </div>
                          </div>
                          <div
                            class="row justify-content-center my-1 mx-1 text-center"
                          >
                            <span class="font-weight-bold text-word-break">{{
                              item.member_name
                            }}</span>
                          </div>
                          <div
                            class="row no-gutters"
                            style="
                              background: #fc6775;
                              border-radius: 0 0 11px 11px;
                            "
                          >
                            <div class="col d-flex justify-content-center py-1">
                              <div class="mb-1">
                                <b-badge
                                  variant="success"
                                  class="py-1"
                                  style="
                                    visibility: hidden;
                                    border-radius: 15px 0 0 15px;
                                    font-size: 8.1px;
                                    width: 3.4rem;
                                    background-color: #617270;
                                  "
                                  >KONFIRM</b-badge
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Undangan">
            <div class="container">
              <div class="row my-4">
                <div class="col-12">
                  <Invitation
                    :pitching_event_id="memberCollection.pitching_event_id"
                  />
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Konfirmasi">
            <div class="container">
              <div class="row my-4">
                <div class="col-12">
                  <div class="">
                    <div class="">
                      <b-table
                        striped
                        hover
                        :items="participant_confirmations"
                        :fields="fieldsConfirmation"
                        class="mt-3"
                        responsive
                      >
                        <template #table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"
                            :key="field.key"
                            :style="{
                              width: field.key === 'action' ? '15%' : '',
                            }"
                          />
                        </template>

                        <template #cell(number)="data">
                          <span>{{ data.index + 1 }}</span>
                        </template>

                        <template #cell(business_name)="data">
                          <span>{{ data.item.member2.business_name }}</span>
                        </template>

                        <template #cell(business_adress)="data">
                          <span>{{ data.item.member2.business_address }}</span>
                        </template>
                      </b-table>
                      <!-- <table class="table table-striped">
                        <tr>
                          <th>No</th>
                          <th>Nama</th>
                          <th>Nama Bisnis</th>
                          <th>Alamat Bisnis</th>
                        </tr>
                        <draggable
                          v-model="participant_confirmations"
                          tag="tbody"
                          @update="update"
                          @input="input"
                          @change="changeOrder"
                        >
                        <tr
                          v-for="(item, index) in participant_confirmations"
                          :key="item.id"
                          style="cursor: pointer"
                        >
                          <td>{{ index + 1 }}</td>
                          <td>{{ item.member_name }}</td>
                          <td>{{ item.member2.business_name }}</td>
                          <td>{{ item.member2.business_address }}</td>
                        </tr>
                        </draggable>
                      </table> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Skor">
            <div class="container">
              <div class="row my-4">
                <div class="col-12">
                  <Score
                    :pitching_event_id="memberCollection.pitching_event_id"
                  />
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <b-modal id="modal-add-participant" :title="titleModal2" @ok="form">
      <b-form-group
        id="input-group-member"
        label="Member:"
        label-for="input-member"
      >
        <b-form-input
          id="input-member"
          v-model="memberCollection.member_name"
          placeholder="Member"
          readonly
        ></b-form-input>
        <small class="text-danger">{{ errorMember.member_id }}</small>
      </b-form-group>
      <b-form-group
        id="input-group-pitching-event-name"
        label="Virtual Event:"
        label-for="input-pitching-event-name"
      >
        <b-form-input
          id="input-pitching-event-name"
          v-model="detail.name"
          placeholder="Virtual Event"
          readonly
        ></b-form-input>
        <small class="text-danger">{{ errorMember.pitching_event_id }}</small>
      </b-form-group>
      <b-form-group id="input-group-notes">
        <label for="input-notes"
          >Keterangan: <em class="text-muted">opsional</em></label
        >
        <b-form-textarea
          id="input-notes"
          v-model="memberCollection.notes"
          placeholder="Keterangan"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ errorMember.notes }}</small>
      </b-form-group>
    </b-modal>
    <b-modal
      id="modal-detail-participant"
      :title="titleModal3"
      size="xl"
      hide-footer
    >
      <div class="row">
        <table class="table mt-8 table-detail">
          <tr></tr>
          <tr>
            <!--3  -->
            <td colspan="3" rowspan="3" class="border-0">
              <div class="d-flex justify-content-center">
                <img :src="detailMember.member_image" alt="" width="150" />
              </div>
            </td>

            <th class="pr-1" width="100" style="white-space: nowrap">
              <span v-if="detailMember.member.is_partner == 1"
                >Nama Pemilik UMKM</span
              >
              <span v-if="detailMember.member.is_partner == 0">Nama</span>
            </th>
            <th class="pl-0 pr-0" width="10">
              <span>:</span>
            </th>
            <td class="pl-2">
              <span>{{ detailMember.member.name }}</span>
            </td>
            <!-- <td
              colspan="3"
              rowspan="3"
              class="border-0"
              v-if="
                detailMember.member.youtube_link != null || detailMember.member.youtube_link != ''
              "
            >
              <div class="d-flex justify-content-center">
                <iframe
                  style="width: 100%; height: 100%"
                  :src="detailMember.member.youtube_link"
                >
                </iframe>
              </div>
            </td> -->
          </tr>
          <tr>
            <th class="pr-1">
              <span>Email</span>
            </th>
            <th class="pl-0 pr-0">
              <span>:</span>
            </th>
            <td class="pl-2">
              <span>{{ detailMember.member.email }}</span>
            </td>
          </tr>
          <tr>
            <th class="pr-1">
              <span>Telepon</span>
            </th>
            <th class="pl-0 pr-0">
              <span>:</span>
            </th>
            <td class="pl-2">
              <span>{{ detailMember.member.phone }}</span>
            </td>
          </tr>
          <tr v-if="detailMember.member.is_partner == 1">
            <th colspan="3" class="border-0">
              <span class="text-primary h4"><b>Detail Usaha</b></span>
            </th>
          </tr>
          <tr v-if="detailMember.member.is_partner == 1">
            <th class="pr-1">
              <span>Nama UMKM</span>
            </th>
            <th class="pl-0 pr-0">
              <span>:</span>
            </th>
            <td class="pl-2">
              <span>{{ detailMember.member.business_name }}</span>
            </td>

            <th class="pr-1">
              <span>Tentang UMKM</span>
            </th>
            <th class="pl-0 pr-0">
              <span>:</span>
            </th>
            <td class="pl-2">
              <span>{{ detailMember.member.description }}</span>
            </td>

            <th class="pr-1">
              <span>Alamat UMKM</span>
            </th>
            <th class="pl-0 pr-0">
              <span>:</span>
            </th>
            <td class="pl-2">
              <span>{{ detailMember.member.business_address }}</span>
            </td>
          </tr>
          <tr v-if="detailMember.member.is_partner == 1">
            <th class="pr-1">
              <span>Peran UMKM</span>
            </th>
            <th class="pl-0 pr-0">
              <span>:</span>
            </th>
            <td class="pl-2">
              <span>{{ detailMember.member.member_role_name }}</span>
            </td>
            <th class="pr-1">
              <span>Industri UMKM</span>
            </th>
            <th class="pl-0 pr-0">
              <span>:</span>
            </th>
            <td class="pl-2" colspan="4">
              <span>{{ detailMember.member.industry_type_name }}</span>
            </td>
          </tr>
        </table>
      </div>
      <!-- <div class="row">
        <div class="col-md-3">
          <img :src="detailMember.member_image" width="200" alt="" />
        </div>
        <div class="col-md-9">
          <table class="table">
            <tr>
              <th class="pr-1" width="130"><span>Nama</span></th>
              <th class="pl-0"><span>:</span></th>
              <td class="pl-2">
                <span>{{ detailMember.member.name }}</span>
              </td>
            </tr>
            <tr>
              <th class="pr-1" width="130"><span>Email</span></th>
              <th class="pl-0"><span>:</span></th>
              <td class="pl-2">
                <span>{{ detailMember.member.email }}</span>
              </td>
            </tr>
            <tr>
              <th class="pr-1" width="130"><span>Phone</span></th>
              <th class="pl-0"><span>:</span></th>
              <td class="pl-2">
                <span>{{ detailMember.member.phone }}</span>
              </td>
            </tr>
            <tr
              v-if="
                detailMember.member.member_role_id != 6 &&
                detailMember.member.member_role_id != 9
              "
            >
              <th class="pr-1" width="130"><span>Nama Bisnis</span></th>
              <th class="pl-0"><span>:</span></th>
              <td class="pl-2">
                <span>{{ detailMember.member.business_name }}</span>
              </td>
            </tr>
            <tr
              v-if="
                detailMember.member.member_role_id != 6 &&
                detailMember.member.member_role_id != 9
              "
            >
              <th class="pr-1" width="130"><span>Informasi Bisnis</span></th>
              <th class="pl-0"><span>:</span></th>
              <td class="pl-2">
                <span>{{ detailMember.member.description }}</span>
              </td>
            </tr>
            <tr
              v-if="
                detailMember.member.member_role_id != 6 &&
                detailMember.member.member_role_id != 9
              "
            >
              <th class="pr-1" width="130"><span>Alamat Bisnis</span></th>
              <th class="pl-0"><span>:</span></th>
              <td class="pl-2">
                <span>{{ detailMember.member.business_address }}</span>
              </td>
            </tr>
          </table>
        </div>
      </div> -->
    </b-modal>
    <ModalMember
      @chooseMember="chooseMember"
      :pitching_event_id="memberCollection.pitching_event_id"
      :apiPurpose="apiPurpose"
      :titleModal="titleModal"
    />

    <!-- modal pitching result -->
    <b-modal
      id="modal-form-pitching-result"
      title="Form Skor"
      @ok="formPitchingResult"
    >
      <b-form-group
        id="input-group-pitching-participant-name"
        label="Peserta:"
        label-for="input-pitching-participant-name"
      >
        <b-form-input
          id="input-pitching-participant-name"
          v-model="pitchingResultForm.participant_name"
          placeholder="Peserta"
          readonly
        ></b-form-input>
        <small class="text-danger">{{
          pitchingResultError.participant_name
        }}</small>
      </b-form-group>
      <b-form-group id="input-group-role" label="Narasumber:" label-for="input-role">
        <treeselect
          v-model="pitchingResultForm.pitching_judger_id"
          :multiple="false"
          placeholder="Pilih Narasumber"
          :options="judgerOptions"
        />
        <small class="text-danger">{{ pitchingResultError.pitching_judger_id }}</small>
      </b-form-group>
      <b-form-group
        id="input-group-score"
        label="Nilai:"
        label-for="input-score"
      >
        <b-form-input
          id="input-score"
          v-model="pitchingResultForm.score"
          placeholder="Nilai"
          @keyup="scoreValidation"
        ></b-form-input>
        <small class="text-danger">{{ pitchingResultError.score }}</small>
      </b-form-group>
      <b-form-group id="input-group-feedback">
        <label for="input-feedback">Masukan: </label>
        <b-form-textarea
          id="input-feedback"
          v-model="pitchingResultForm.feedback"
          placeholder="Masukan"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ pitchingResultError.feedback }}</small>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalMember from "@/view/components/general/ModalMember.vue";
import draggable from "vuedraggable";
import Invitation from "@/view/components/pitching-events/Invitation.vue";
import Score from "@/view/components/pitching-events/Score.vue";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  components: {
    ModalMember,
    Invitation,
    Score,
    draggable,
  },
  data() {
    return {
      fieldsConfirmation: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "member_name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "business_name",
          label: "Nama Bisnis",
          sortable: true,
        },
        {
          key: "business_adress",
          label: "Alamat Bisnis",
          sortable: true,
        },
      ],
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      detail: [],
      memberCollection: {
        member_id: "",
        member_name: "",
        pitching_event_id: "",
        notes: "",
      },
      errorMember: {
        member_id: "",
        member_name: "",
        pitching_event_id: "",
        notes: "",
      },
      route: "",
      detailMember: {
        member: {
          name: "",
          phone: "",
          email: "",
          gender_display: "",
          business_name: "",
          business_address: "",
          business_city: "",
          postal_code: "",
          business_estabilished_age: "",
        },
      },
      // listing
      participants: [],
      participant_confirmations: [],
      judgers: [],
      partners: [],
      hosts: [],
      titleModal: "",
      titleModal2: "",
      titleModal3: "",
      // other
      methodForm: "addParticipant",
      apiPurpose: "participants",
      modalPurpose: "",
      mainProps: { blankColor: "#777", width: 66, height: 79, class: "m1" },
      profilImage: {
        blank: true,
        blankColor: "#fc7886",
        width: 75,
        height: 75,
        class: "m1",
      },
      orderForm: {
        newIndex: "",
        oldIndex: "",
        participant_id: "",
      },
      pitchingResultForm: {
        participant_name: "",
        pitching_judger_id: "",
      },
      pitchingResultError: {
        pitching_event_id: "",
        pitching_participant_id: "",
        pitching_judger_id: "",
        score: "",
        feedback: "",
        pitching_judger_id: "",
      },
      judgerOptions: [],
    };
  },
  methods: {
    // mobile detection
    isMobile() {
      var checkIsMobile = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          checkIsMobile = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return checkIsMobile;
    },

    async get(id) {
      this.detail = await module.get("api/pitching-events/" + id);
      // If Data Not Found
      if (this.detail == null) {
        // Redirect To List
        this.$router.push("/pitching-events");
      } else {
      }
    },
    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/pitching-events/" + id);
      // If Deleted
      if (result) {
        this.$router.push("/pitching-events");
      }
    },

    async deleteParticipant(route) {
      // Delete Data
      let result = await module.delete(`${route}`);
      // If Deleted
      if (result) {
        this.$root.$emit("deleteParticipantEmit");
        this.getParticipant(this.memberCollection.pitching_event_id);
        this.getJudger(this.memberCollection.pitching_event_id);
        this.getPartner(this.memberCollection.pitching_event_id);
        this.getHost(this.memberCollection.pitching_event_id);
      }
    },

    async getParticipantById(participant_id, resource) {
      this.modalPurpose = "edit";
      if (resource == "participants") {
        this.memberCollection = await module.get(
          "api/pitching-participants/" + participant_id
        );
        this.titleModal2 = "Edit Peserta";
        this.route = `api/pitching-participants/${this.memberCollection.id}`;
      } else if (resource == "judgers") {
        this.memberCollection = await module.get(
          "api/pitching-judgers/" + participant_id
        );
        this.titleModal2 = "Edit Narasumber";
        this.route = `api/pitching-judgers/${this.memberCollection.id}`;
      } else if (resource == "partners") {
        this.memberCollection = await module.get(
          "api/pitching-partners/" + participant_id
        );
        this.titleModal2 = "Edit Coach";
        this.route = `api/pitching-partners/${this.memberCollection.id}`;
      } else if (resource == "hosts") {
        this.memberCollection = await module.get(
          "api/pitching-hosts/" + participant_id
        );
        this.titleModal2 = "Edit Moderator";
        this.route = `api/pitching-hosts/${this.memberCollection.id}`;
        console.log("route", this.route);
      }
      // If Data Not Found
      if (this.memberCollection == null) {
        // Redirect To List
        // this.$router.push("/pitching-participants");
      } else {
        this.memberCollection["_method"] = "put";
        this.methodForm = "editParticipant";
        this.$bvModal.show("modal-add-participant");
      }
    },
    async showDetailParticipant(participant_id, resource) {
      this.modalPurpose = "detail";
      console.log("modal purposer", this.modalPurpose);
      if (resource == "participants") {
        this.detailMember = await module.get(
          "api/pitching-participants/" + participant_id
        );
        this.titleModal3 = "Detail Peserta dan Mitra";
      } else if (resource == "judgers") {
        this.detailMember = await module.get(
          "api/pitching-judgers/" + participant_id
        );
        this.titleModal3 = "Detail Narasumber";
      } else if (resource == "partners") {
        this.detailMember = await module.get(
          "api/pitching-partners/" + participant_id
        );
        this.titleModal3 = "Detail Coach";
      } else if (resource == "hosts") {
        this.detailMember = await module.get(
          "api/pitching-hosts/" + participant_id
        );
        this.titleModal3 = "Detail Moderator";
      }
      // If Data Not Found
      if (this.detailMember == null) {
        // // // // //
      } else {
        this.detailMember["_method"] = "put";
        console.log("show");
        if (this.modalPurpose == "detail") {
          this.$bvModal.show("modal-detail-participant");
        }
      }
    },

    addParticipant(apiPurpose) {
      this.apiPurpose = apiPurpose;
      if (apiPurpose == "participants") {
        this.titleModal = "Peserta dan Mitra";
        this.titleModal2 = "Tambah Peserta dan Mitra";
      } else if (apiPurpose == "judgers") {
        this.titleModal = "Narasumber";
        this.titleModal2 = "Tambah Narasumber";
      } else if (apiPurpose == "partners") {
        this.titleModal = "Coach";
        this.titleModal2 = "Tambah Coach";
      } else if (apiPurpose == "hosts") {
        this.titleModal = "Moderator";
        this.titleModal2 = "Tambah Moderator";
      }
      this.$bvModal.show("modal-members");
    },
    chooseMember(value) {
      this.memberCollection.member_id = value.id;
      this.memberCollection.member_name = value.name;
      if (this.apiPurpose == "participants") {
        this.route = "api/pitching-participants";
      } else if (this.apiPurpose == "judgers") {
        this.route = "api/pitching-judgers";
      } else if (this.apiPurpose == "partners") {
        this.route = "api/pitching-partners";
      } else if (this.apiPurpose == "hosts") {
        this.route = "api/pitching-hosts";
      }

      this.$bvModal.hide("modal-members");
      this.$bvModal.show("modal-add-participant");
    },
    async form(bvModalEvt) {
      bvModalEvt.preventDefault();
      // if (this.methodForm == "editParticipant") {
      //   this.route = `api/pitching-participants/${this.memberCollection.id}`;
      // }
      // Make Request
      let response = await module.submit(this.memberCollection, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.errorMember = validation.setValidationError(
          this.errorMember,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$bvModal.hide("modal-add-participant");
        if (this.apiPurpose == "participants") {
          this.getParticipant(this.memberCollection.pitching_event_id);
        } else if (this.apiPurpose == "judgers") {
          this.getJudger(this.memberCollection.pitching_event_id);
        } else if (this.apiPurpose == "partners") {
          this.getPartner(this.memberCollection.pitching_event_id);
        } else if (this.apiPurpose == "hosts") {
          this.getHost(this.memberCollection.pitching_event_id);
        }
        this.memberCollection.notes = "";
        this.methodForm = "addParticipant";
        this.detailMember["_method"] = "post";
        this.memberCollection["_method"] = "post";

        this.$root.$emit("refreshInvitation");
      }
    },

    // listing
    // get participant
    async getParticipant(pitching_event_id) {
      let filterParams = `pitching_event_id=${pitching_event_id}`;
      let response = await module.paginate(
        "api/pitching-participants/no-pagination",
        `?${filterParams}`
      );

      this.participants = response.data;
      console.log(this.participants);
    },
    // async getParticipantConfirmation(pitching_event_id) {
    //   let filterParams = `pitching_event_id=${pitching_event_id}&confirmation_status=1`;
    //   let response = await module.paginate(
    //     "api/pitching-participants/no-pagination",
    //     `?${filterParams}`
    //   );

    //   this.participant_confirmations = response.data;
    // },
    async getParticipantConfirmation(pitching_event_id) {
      let filterParams = `&pitching_event_id=${pitching_event_id}&confirmation_status=1`;
      let response = await module.paginate(
        "api/pitching-participants",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      // let pagination = response.meta.pagination;
      // this.totalRows = pagination.total;

      // let a, b;
      // b = this.perPage * (this.currentPage - 1) + 1;
      // for (a = 0; a < response.data.length; a++) {
      //   response.data[a].number = b + a;
      // }

      this.participant_confirmations = response.data;
    },

    // get judger
    async getJudger(pitching_event_id) {
      let filterParams = `pitching_event_id=${pitching_event_id}`;
      let response = await module.paginate(
        "api/pitching-judgers",
        `?${filterParams}`
      );

      this.judgers = response.data;

      if (this.judgers.length > 0) {
        this.judgers.forEach((value) => {
          this.judgerOptions.push({ label: value.member_name, id: value.id });
        });

        console.log("judger opt", this.judgerOptions);
      }
    },

    // get partner
    async getPartner(pitching_event_id) {
      let filterParams = `pitching_event_id=${pitching_event_id}`;
      let response = await module.paginate(
        "api/pitching-partners",
        `?${filterParams}`
      );

      this.partners = response.data;
    },

    // get host
    async getHost(pitching_event_id) {
      let filterParams = `pitching_event_id=${pitching_event_id}`;
      let response = await module.paginate(
        "api/pitching-hosts",
        `?${filterParams}`
      );

      this.hosts = response.data;
    },

    // get pitching result by participant
    async getPitchingResultByParticipant(participantId, participantName) {
      this.pitchingResultForm.participant_name = participantName;
      this.pitchingResultForm = await module.get(
        `api/pitching-results/events/${
          this.memberCollection.pitching_event_id
        }/participants/${participantId}/judgers/-1`
      );
      // If Data Not Found
      if (this.pitchingResultForm == null) {
        //
        this.pitchingResultForm = {};
        this.pitchingResultForm.participant_name = participantName;
        this.pitchingResultForm.pitching_participant_id = participantId;
        // this.pitchingResultForm.pitching_judger_id = '';
        console.log(this.pitchingResultForm);
        this.$bvModal.show("modal-form-pitching-result");
      } else {
        // this.pitchingResultForm['_method'] = 'put'
        console.log(this.pitchingResultForm);
        this.$bvModal.show("modal-form-pitching-result");
      }
    },

    scoreValidation(){
      if(this.pitchingResultForm.score > 20){
        this.pitchingResultForm.score = 20
      }
    },

    // form pitching result
    async formPitchingResult(bvModalEvt) {
      bvModalEvt.preventDefault();
      // this.pitchingResultForm.pitching_judger_id = getUser().id;
      this.pitchingResultForm.pitching_event_id =
        this.memberCollection.pitching_event_id;
      // Make Request
      let response = await module.submit(
        this.pitchingResultForm,
        `api/pitching-results/events/${this.memberCollection.pitching_event_id}/participants/${this.pitchingResultForm.pitching_participant_id}/judgers/${this.pitchingResultForm.pitching_judger_id}/set-scores`
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.pitchingResultError = validation.setValidationError(
          this.pitchingResultError,
          response.error.errors
        );
      } else {
        this.$bvModal.hide("modal-form-pitching-result");
        this.pitchingResultForm.feedback = "";
        this.pitchingResultForm.score = "";
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$root.$emit("successfullyPitchingResult");
      }
    },

    // ==================================== Tab 2 ======================================
    update(evt) {
      console.log(evt);
    },
    input(evt) {
      console.log(evt);
    },
    async changeOrder(evt) {
      this.orderForm["_method"] = "put";
      this.orderForm.newIndex = evt.moved.newIndex;
      this.orderForm.oldIndex = evt.moved.oldIndex;
      this.orderForm.id = evt.moved.element.id;
      let response = await module.submitWithoutAlert(
        this.orderForm,
        `api/pitching-participants/change-order/${this.memberCollection.pitching_event_id}`
      );
      if (response.state != "error") {
        console.log("res", response);
      } else {
        console.log("res", response);
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Virtual Event", route: "/pitching-events" },
      { title: "Detail" },
    ]);

    this.get(this.$route.params.id);
    this.getParticipant(this.$route.params.id);
    this.getParticipantConfirmation(this.$route.params.id);
    this.getJudger(this.$route.params.id);
    this.getPartner(this.$route.params.id);
    this.getHost(this.$route.params.id);
    this.memberCollection.pitching_event_id = this.$route.params.id;

    this.$root.$emit("runPaginationInvitation", this.$route.params.id);
    this.$root.$emit("runPaginationScore", this.$route.params.id);
  },

  beforeRouteUpdate(to, from, next) {
    this.get(to.params.id);
    this.getParticipant(to.params.id);
    this.getParticipantConfirmation(to.params.id);
    this.getJudger(to.params.id);
    this.getPartner(to.params.id);
    this.getHost(to.params.id);
    this.memberCollection.pitching_event_id = to.params.id;
    next();
  },
};
</script>

<style scoped>
.card-body {
  height: 150px;
}

.card h2 {
  color: white;
}

.filter-image {
  position: absolute;
  background-color: black;
  width: 100%;
  height: 150px;
  opacity: 0.5;
}

.place-title {
  position: absolute;
  width: 100%;
  height: 150px;
}

/* .place-title .btn-action {
  position: absolute;
  right: 1%;
  top: 6%;
} */

.place-title .btn-edit {
  position: absolute;
  right: 1%;
  top: 6%;
}

.place-title .btn-delete {
  position: absolute;
  right: 7%;
  top: 6%;
}

.classroom-title {
  position: absolute;
  top: 30px;
  left: 25px;
  /* text-shadow: 2px 2px #244ca3; */
}

.wizard-steps {
  height: 230px;
  width: 230px;
}

.wizard-steps img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* my-card */
.my-card-custom {
  background-color: #f1eff0;
  border-radius: 5%;
  width: 10rem;
}

.text-word-break{
  word-break: break-word;
}
</style>