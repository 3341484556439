<template>
  <div>
    <b-modal
      id="modal-members"
      :title="titleModal"
      size="xl"
      no-close-on-backdrop
      hide-footer
    >
      <Table purpose="modal" @chooseMember="chooseMember" :pitching_event_id="pitching_event_id" :apiPurpose="apiPurpose" />
    </b-modal>
  </div>
</template>

<script>
import Table from "@/view/components/members/Table.vue";
export default {
  props: {
    pitching_event_id: String,
    apiPurpose: String,
    titleModal: {
      type: String,
      default: "Member"
    }
  },
  components: {
    Table,
  },
  methods: {
    chooseMember(value) {
      this.$emit("chooseMember", value);
    },
  },
};
</script>

<style>
</style>