<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <!-- Filter -->
          <div class="row justify-content-end mt-n3">
            <div class="col-md-4">
              <b-input-group>
                <b-form-input
                  type="text"
                  v-model="filter.name"
                  placeholder="Cari Nama"
                ></b-form-input>
                <template #append>
                  <b-button variant="primary" @click="filterByName">Cari</b-button>
                  <b-button variant="danger" @click="resetFilter">Reset</b-button>
                </template>
              </b-input-group>
            </div>
          </div>
          <!-- <div class="d-flex justify-content-end" v-if="purpose == 'modal'">
            <div class="">
              <b-form-checkbox
                checked=""
                :id="`checkbox-pick-item-check-all`"
                :name="`checkbox-pick-item-check-all`"
                @change="checkAll"
                >Pilih Semua
              </b-form-checkbox>
            </div>
          </div> -->
          <b-table
            striped
            hover
            :items="items"
            :fields="apiPurpose != 'participants' ? fields2 : fields"
            class="mt-3"
            responsive
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.key === 'action' ? '5%' : '' }"
              />
            </template>

            <template #cell(photo_url)="data">
              <div
                class="
                  d-flex
                  justify-content-start
                  align-items-center
                  image-container
                "
              >
                <div class="image-input-wrapper" style="position: relative">
                  <img class="image" :src="data.item.photo_url" />
                </div>
              </div>
            </template>

            <template #cell(action)="data">
              <template v-if="purpose == 'master'">
                <b-button
                  size="sm"
                  class="mr-1 btn-outline-info btn-circle"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="
                    $router.push({
                      path: '/members/detail/' + data.item.id,
                    })
                  "
                  ><i class="fas fa-eye px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-outline-success btn-circle"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="
                    $router.push({
                      path: '/members/edit/' + data.item.id,
                    })
                  "
                  v-if="data.item.status != 'special' && btnAccess"
                  ><i class="fas fa-edit px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-outline-danger btn-circle"
                  v-b-tooltip.hover
                  title="Hapus"
                  @click="deleteData(data.item.id)"
                  v-if="data.item.status != 'special' && btnAccess"
                  ><i class="fas fa-trash px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="btn-outline-success btn-circle"
                  v-b-tooltip.hover
                  title="Info"
                  @click="data.toggleDetails"
                  v-if="btnAccess"
                  ><i class="fas fa-info px-0"></i
                ></b-button>
              </template>
              <template v-if="purpose == 'modal'">
                <b-button
                  variant="outline-primary"
                  class="btn-circle svg-icon-danger"
                  v-b-tooltip.hover
                  title="Pilih"
                  @click="
                    chooseMember({
                      id: data.item.id,
                      name: data.item.name,
                    })
                  "
                  >
                  <!-- <inline-svg src="/media/svg/icons/Design/Interselect.svg"  class="svg-icon-primary"/> -->
                  Pilih
                  </b-button>
                <b-button
                  size="sm"
                  class="btn-outline-success btn-circle ml-1"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="data.toggleDetails"
                >
                  <i class="fas fa-eye px-0" v-if="!data.detailsShowing"></i>
                  <i
                    class="fas fa-eye-slash px-0"
                    v-if="data.detailsShowing"
                  ></i>
                </b-button>
              </template>
            </template>

            <template #cell(industry_type)>
              <span>PT (Perseroan Terbatas)</span>
            </template>

            <template #row-details="data">
              <div class="row justify-content-center">
                    <div class="col-12">
                      <div class="card card-custom">
                        <div class="card-body p-0">
                          <!--begin: Wizard-->
                          <div
                            class="wizard wizard-1"
                            id="kt_wizard_v1"
                            data-wizard-state="step-first"
                            data-wizard-clickable="true"
                          >
                            <!--begin: Wizard Body-->
                            <div
                              class="
                                row
                                justify-content-center
                                my-4
                                px-4
                                my-lg-5
                                px-lg-5
                              "
                            >
                              <div class="col-xl-12">
                                <!--begin: Wizard Form-->

                                <!--begin: Wizard Step 1-->
                                <div
                                  class="pb-5"
                                  data-wizard-type="step-content"
                                  data-wizard-state="current"
                                >
                                  <h5
                                    class="
                                      font-weight-bolder
                                      text-primary
                                      font-size-h4 font-size-h4-lg
                                    "
                                  >
                                    Detail Anggota
                                  </h5>
                                  <table class="table mt-8 table-detail">
                                    <tr></tr>
                                    <tr>
                                      <!--3  -->
                                      <td
                                        colspan="3"
                                        rowspan="4"
                                        class="border-0"
                                      >
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            :src="data.item.photo_url"
                                            alt=""
                                            style="max-width: 150px"
                                          />
                                        </div>
                                      </td>

                                      <th
                                        class="pr-1"
                                        width="100"
                                        style="white-space: nowrap"
                                      >
                                        <span>Nama Pemilik UMKM</span>
                                      </th>
                                      <th class="pl-0 pr-0" width="10">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{ data.item.name }}</span>
                                      </td>

                                      <td
                                        colspan="3"
                                        rowspan="4"
                                        class="border-0"
                                        v-if="data.item.youtube_link != null || data.item.youtube_link != ''"
                                      >
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <iframe style="width:100%; height:100%" :src="data.item.youtube_link">
                                          </iframe>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th class="pr-1">
                                        <span>Jenis Kelamin</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{ data.item.gender_display }}</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th class="pr-1">
                                        <span>Email</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{ data.item.email }}</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th class="pr-1">
                                        <span>Telepon</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{ data.item.phone }}</span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <th colspan="3" class="border-0">
                                        <span class="text-primary h4"
                                          ><b>Detail Usaha</b></span
                                        >
                                      </th>
                                    </tr>
                                    <tr>
                                      <th class="pr-1">
                                        <span>Nama UMKM</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{
                                          data.item.business_name
                                        }}</span>
                                      </td>

                                      <th class="pr-1">
                                        <span>Tentang UMKM</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{ data.item.description }}</span>
                                      </td>

                                      <th class="pr-1">
                                        <span>Peran UMKM</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{
                                          data.item.member_role_name
                                        }}</span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <th class="pr-1">
                                        <span>Industri UMKM</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{
                                          data.item.industry_type_name
                                        }}</span>
                                      </td>
                                      <th class="pr-1">
                                        <span>Lama Usaha</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{
                                          data.item.business_estabilished_age
                                        }} Tahun</span>
                                      </td>
                                      <th class="pr-1">
                                        <span>Pendapatan/bulan</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{
                                          data.item.monthly_omset
                                        }}</span>
                                      </td>
                              
                                    </tr>

                                    <tr>
                                      <th class="pr-1">
                                        <span>Lama Usaha</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span
                                          >{{
                                            data.item.business_estabilished_age
                                          }}
                                          Tahun</span
                                        >
                                      </td>
                                      <th class="pr-1">
                                        <span>Jenis Usaha</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{
                                          data.item.industry_type_name
                                        }}</span>
                                      </td>
                                      <th class="pr-1">
                                        <span>Badan Usaha</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2" colspan="4">
                                        <span>{{
                                          data.item.business_legal_type_name
                                        }}</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th class="pr-1">
                                        <span>Alamat UMKM</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{
                                          data.item.business_address
                                        }}</span>
                                      </td>
                                      
                                      <th class="pr-1">
                                        <span>Kota Usaha</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{
                                          data.item.business_city
                                        }}</span>
                                      </td>

                                      <th class="pr-1">
                                        <span>Kode Pos</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2" colspan="4">
                                        <span>{{
                                          data.item.postal_code
                                        }}</span>
                                      </td>
                                    </tr>
                                  </table>
                                  <div class="row mx-3">
                                    <span class="text-primary h4"
                                      ><b>Produk Unggulan</b></span
                                    >
                                  </div>
                                  <div class="row mx-3">
                                    <table class="table">
                                      <tr>
                                        <th>No</th>
                                        <th>Gambar</th>
                                        <th>Nama</th>
                                        <th>Harga</th>
                                      </tr>
                                    <tr
                                      v-for="(item, index) in data.item
                                        .member_products"
                                      :key="index"
                                    >
                                      <td>{{ index + 1 }}</td>
                                      <td>
                                        <div
                                          class="
                                            d-flex
                                            justify-content-start
                                            align-items-center
                                            image-container
                                          "
                                        >
                                          <div
                                            class="image-input-wrapper-product"
                                            style="position: relative"
                                          >
                                            <img
                                              class="image"
                                              :src="item.image"
                                            />
                                          </div>
                                        </div>
                                      </td>
                                      <td>{{ item.name }}</td>
                                      <td>
                                        {{
                                          item.price.toLocaleString("id-ID")
                                        }}
                                      </td>
                                    </tr>
                                    </table>
                                  </div>
                                </div>
                                <!--end: Wizard Step 1-->
                              </div>
                            </div>
                            <!--end: Wizard Body-->
                          </div>
                        </div>
                        <!--end: Wizard-->
                      </div>
                    </div>
                  </div>
            </template>
          </b-table>

          <b-pagination
            v-if="items.length != 0"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @page-click="pageOnClick"
            class="mt-4"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    purpose: {
      type: String,
      default: "add",
    },
    pitching_event_id: {
      type: String,
      default: "",
    },
    apiPurpose: String,
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "photo_url",
          label: "Foto",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
          tdClass: "set-max-width",

        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          tdClass: "set-max-width",
        },
        {
          key: "member_role_name",
          label: "Peran",
          sortable: true,
        },
        {
          key: "is_partner",
          label: "Jenis Peserta",
          sortable: true,
        },
        {
          key: "business_name",
          label: "Nama UMKM",
          sortable: true,
          tdClass: "set-max-width",
        },
        {
          key: "action",
          label: "",
          tdClass: "nowrap",
        },
      ],
      fields2: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "photo_url",
          label: "Foto",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
          tdClass: "set-max-width",
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          tdClass: "set-max-width",
        },
        {
          key: "member_role_name",
          label: "Peran",
          sortable: true,
        },
        {
          key: "action",
          label: "",
          tdClass: "nowrap",
        },
      ],
      items: [],
      memberCollection: {
        member_id: "",
        pitching_event_id: "",
        notes: "",
      },
      // access
      btnAccess: true,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&pitching_event_id=${this.pitching_event_id}&name=${this.filter.name}`;
      console.log(this.apiPurpose,"poseee");
      if (this.apiPurpose == "participants") {
        filterParams += `&apiPurpose=${this.apiPurpose}&member_role_id=1&is_partner=0`;
      } else if (this.apiPurpose == "judgers") {
        filterParams += `&apiPurpose=${this.apiPurpose}&member_role_id=6&is_partner=0`;
      } else if (this.apiPurpose == "partners") {
        filterParams += `&apiPurpose=${this.apiPurpose}&member_role_id=10&is_partner=1`;
      } else if (this.apiPurpose == "hosts") {
        filterParams += `&apiPurpose=${this.apiPurpose}&member_role_id=7&is_partner=0`;
      }
      
      let response = await module.paginate(
        "api/members/select-modal-member",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    filterByName() {
      this.pagination();
    },

    resetFilter() {
      this.filter.name = ''
      this.pagination()
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/members/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    checkAll() {},
    chooseMember(value) {
      this.memberCollection.member_id = value.id;
      this.$emit("chooseMember", value);
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6006") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Anggota", route: "/members" },
    ]);
    this.pagination();
    this.checkAccess();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
.btn-circle {
  width: 40px;
  height: 40px;
  border-radius: 35px;
}

.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.set-max-width {
  word-break: break-word;
  max-width: 14rem;
}
</style>
